<template>
  <div>
    <h4>PROVEEDORES</h4>
    <app-table-registers ref="tr" :getList="getList">
      <template slot="tl">
        <div class="col">
          <button
            class="btn btn-primary mr-1"
            @click="
              $refs.dFormCr.show();
              $refs.formCr.reset();
            "
          >
            <i class="fa fa-plus"></i> Nuevo
          </button>
        </div>
        <!-- <button class="btn btn-primary" @click=""><i class="fa fa-plus"></i> Cliente a provee</button> -->
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>PROVEEDOR</th>
            <th>DIRECCION</th>
            <th>TELEFONO</th>
            <th>CORREO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.id }}</td>
            <td>{{ l.name }}</td>
            <td>{{ l.address }}</td>
            <td>
              <a :href="'tel:' + l.phone">
                <app-span-phonenumber :number="l.phone"></app-span-phonenumber>
              </a>
            </td>
            <td>
              <a :href="'mailto:' + l.email">{{ l.email }}</a>
            </td>
            <td>
              <div class="btn-group">
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.formCr.loadReg(l);
                    $refs.dFormCr.show();
                  "
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-light btn-sm">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dFormCr" :lg="true">
      <Form
        ref="formCr"
        @submitted="
          $refs.dFormCr.hide();
          $refs.tr.gl();
        "
      ></Form>
    </app-modal-basic>
  </div>
</template>

<script>
import Form from "./Form";
import { LogisticService } from "../service";

export default {
  components: {
    Form
  },
  data: () => ({
    list: {}
  }),
  methods: {
    getList(params) {
      return new Promise(rsv => {
        LogisticService.getSuppliers(params).then(rs => {
          this.list = rs;
          rsv(rs);
        });
      });
    }
  }
};
</script>

<style>
</style>
