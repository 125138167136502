<template>
  <form @submit="save()">
    <!-- user_id -->
    <!-- name -->
    <!-- contact_name -->
    <!-- phone -->
    <!-- country -->
    <!-- region -->
    <!-- account_number -->
    <!-- bank -->
    <!-- company_name -->
    <!-- doc_type -->
    <!-- identity -->
    <!-- address -->
    <!-- postal_code -->
    <!-- home_page -->
    <!-- email -->
    <!-- observation -->
    <!-- products_stock -->
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for>Nombre*</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.name"
            class="form-control invalid"
          />
          <app-small-form-errors :errors="errors.name"></app-small-form-errors>
          <!-- <app-div-form-errors :errors="errors.name"></app-div-form-errors> -->
        </div>
        <div class="form-group">
          <label for>Nombre del contacto</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.contact_name"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for>Teléfono*</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.phone"
            class="form-control"
          />
          <app-small-form-errors :errors="errors.phone"></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Pais</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.country"
            class="form-control"
          />
          <app-small-form-errors
            :errors="errors.country"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Region</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.region"
            class="form-control"
          />
          <app-small-form-errors
            :errors="errors.region"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Banco</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.bank"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for>Numero de Cuenta</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.account_number"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for>Nombre de Empresa, Razon social</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.company_name"
            class="form-control"
          />
        </div>

        <button class="btn btn-primary" @click="$refs.dSetCli.show()">
          Seleccionar Cliente
        </button>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for>Tipo Documento*</label>
          <SelectIdCard v-model="reg.doc_type"></SelectIdCard>
          <app-small-form-errors
            :errors="errors.doc_type"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Numero Documento*</label>
          <div class="input-group">
            <input
              @focus="setErrors()"
              type="text"
              v-model="reg.identity"
              class="form-control"
            />
            <div class="input-group-append" v-show="reg.doc_type == 2">
              <button
                class="btn btn-light"
                @click="loadFromSunat()"
                title="Validar datos con la sunat"
              >
                <i class="fa fa-spinner fa-spin" v-show="loadRucData"></i>
                <i class="fa fa-check" v-show="!loadRucData"></i>
              </button>
            </div>
          </div>

          <app-small-form-errors
            :errors="errors.identity"
          ></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Dirección</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.address"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for>Codigo Postal</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.postal_code"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for>Pagina web</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.home_page"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for>Correo*</label>
          <input
            @focus="setErrors()"
            type="email"
            v-model="reg.email"
            class="form-control"
          />
          <app-small-form-errors :errors="errors.email"></app-small-form-errors>
        </div>
        <div class="form-group">
          <label for>Observación</label>
          <app-textarea v-model="reg.observation"></app-textarea>
        </div>
        <div class="form-group">
          <label for>Productos de Referencia</label>
          <input
            @focus="setErrors()"
            type="text"
            v-model="reg.products_stock"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <app-button-submit
      class="ml-3"
      @click="save()"
      :disabled="errors"
    ></app-button-submit>

    <app-modal-basic ref="dInfoSupplier">
      <p v-for="(l, index) in supplierInfo" :key="index">
        <b>{{ index }}</b>
        {{ l }}
      </p>
      <button class="btn btn-light" @click="$refs.dInfoSupplier.hide()">
        OK
      </button>
    </app-modal-basic>

    <app-modal-basic ref="dSetCli">
      <h4>SELECCIONAR CLIENTE</h4>
      <SelectClient
        ref="selCli"
        v-model="client_id"
        class="mb-2"
      ></SelectClient>
      <button class="btn btn-primary" @click="loadClient()">Cargar</button>
    </app-modal-basic>
  </form>
</template>

<script>
import SelectIdCard from "../../store-module/clients/SelectIdCard";
import validate from "validate.js";
import { LogisticService } from "../service";
import SelectClient from "../../store-module/clients/Select";

const formRules = {
  name: { presence: { message: "Requerido", allowEmpty: false } },
  phone: { presence: { message: "Requerido", allowEmpty: false } },
  doc_type: { presence: { message: "Requerido", allowEmpty: false } },
  identity: { presence: { message: "Requerido", allowEmpty: false } },
  email: { presence: { message: "Requerido", allowEmpty: false } },
  country: { presence: { message: "Requerido", allowEmpty: false } },
  region: { presence: { message: "Requerido", allowEmpty: false } }
};

export default {
  components: {
    SelectIdCard,
    SelectClient
  },
  data: () => ({
    reg: {},
    loadRucData: false,
    supplierInfo: {},
    errors: {},
    client_id: undefined
  }),
  watch: {
    reg: {
      handler() {
        this.setErrors();
      },
      deep: true
    }
  },
  methods: {
    loadClient() {
      this.reg.name = this.$refs.selCli.val.name;
      this.reg.phone = this.$refs.selCli.val.cell_phone_number;
      this.reg.doc_type = this.$refs.selCli.val.idcard_type_id;
      this.reg.identity = this.$refs.selCli.val.idcard;
      this.reg.email = this.$refs.selCli.val.email;
      this.reg.client_id = this.client_id;
      this.$refs.dSetCli.hide();
      this.$forceUpdate();
      this.setErrors();
    },
    setErrors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      this.errors = err ? err : false;
    },
    loadFromSunat() {
      if (this.reg.doc_type == 2) {
        this.loadRucData = true;
        // this.$forceUpdate();
        LogisticService.getRucData(this.reg.identity).then(res => {
          // console.log(res, res.razon_social);
          // this.reg = {
          //   company_name: res.razon_social,
          //   name: res.razon_social
          // };
          this.reg.company_name = res.razon_social;
          this.reg.name = res.razon_social;
          this.supplierInfo = res;
          this.loadRucData = false;
          this.setErrors();
          this.$refs.dInfoSupplier.show();
          // setTimeout(() => {
          //   this.$forceUpdate();
          // }, 200);
        });
      }
    },
    reset() {
      this.reg = {
        country: "PERU",
        region: "PUNO "
      };
    },
    save() {
      // this.$forceUpdate();
      if (!this.errors) {
        LogisticService.saveSupplier(this.reg).then(res => {
          this.$emit("submitted", res);
        });
      }
    },
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
    }
  }
};
</script>

<style></style>
